import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/lib/utils';

const buttonVariants = cva(
	'inline-flex flex flex-row gap-2 items-center justify-center whitespace-nowrap rounded-md text-md ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300',
	{
		variants: {
			variant: {
				primary: 'bg-maven-primary-600 text-neutral-50 hover:bg-maven-primary-500 active:bg-maven-primary-400 font-semibold',
				secondary:
					'text-neutral-900 hover:bg-neutral-200 active:bg-neutral-300 dark:bg-neutral-800 dark:text-neutral-50 dark:hover:bg-neutral-700 dark:active:bg-neutral-600',
				outline:
					'border border-neutral-200 bg-white hover:bg-neutral-100 active:bg-neutral-200 dark:border-neutral-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:active:bg-neutral-700 dark:text-neutral-50',

				default: 'bg-neutral-900 text-neutral-50 hover:bg-neutral-100 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90',
				destructive: 'bg-maven-error-400 hover:bg-maven-error-500 active:bg-maven-error-600 text-neutral-50',
				ghost: 'hover:bg-neutral-100 hover:text-neutral-900 dark:hover:bg-neutral-800 dark:hover:text-neutral-50',
				link: 'text-neutral-900 underline-offset-4 hover:underline dark:text-neutral-50',
				lock: 'border border-neutral-400 bg-neutral-200 text-neutral-700 cursor-not-allowed',
				disabled: 'text-neutral-400 bg-neutral-50 border-neutral-200 border'
			},
			size: {
				default: 'h-10 px-3 py-1.5',
				sm: 'h-9 rounded-md px-2 py-1',
				lg: 'h-11 rounded-md px-6 py-2',
				icon: 'h-10 w-10'
			}
		},
		defaultVariants: {
			variant: 'default',
			size: 'default'
		}
	}
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants>
{
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) =>
{
	const Comp = asChild ? Slot : 'button';
	return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button, buttonVariants };
