'use client';

import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import CalendarIcon from '@material-symbols/svg-400/outlined/calendar_month.svg';
import { cn } from '@/utils/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { DropdownMenu } from './dropdown-menu';
import EnumDropdown from '../Enum/EnumDropdown';
import { DayPicker } from 'react-day-picker';
import ChevronLeft from '@material-symbols/svg-400/outlined/chevron_left.svg';
import ChevronRight from '@material-symbols/svg-400/outlined/chevron_right.svg';
import ContentCopy from '@material-symbols/svg-400/outlined/content_copy.svg';
import Check from '@material-symbols/svg-400/outlined/check.svg';
import { KeybindTooltip } from '../Help/KeybindManager';
import CopyText from '../Message/CopyText';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip';
import { z_classes } from '@/utils/z';
import { Portal } from '@radix-ui/react-tooltip';

const dateFormat = 'MM/dd/yyyy';
const dateRegex = /(\d{1,2})\D(\d{1,2})\D(\d{4})/;

export { dateFormat, dateRegex };

export function DatePicker({ date, setDate }: { date: Date; setDate: any })
{
	//Separate year, month, and day so they can bn individually selected
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const years = Array.from({ length: 15 }, (_, i) => i + 2010);
	const [year, setYear] = useState(date.getFullYear());
	const [month, setMonth] = useState(date.getMonth());
	const [day, setDay] = useState(date.getDate());

	const initialDateStr = useMemo(() =>
	{
		let dateStr;
		try
		{
			dateStr = format(date, dateFormat);
		}
		catch (e)
		{
			dateStr = new Date().toDateString();
		}
		return dateStr;
	}, []);

	const [dateString, setDateString] = useState(initialDateStr);
	const [inputTimer, setInputTimer] = useState<any>(null);

	const [formatTooltipOpen, setFormatTooltipOpen] = useState(false);
	const [matchesFormat, setMatchesFormat] = useState(true);

	//Update the date when any part of it changes:
	useEffect(() =>
	{
		const newDate = new Date(year, month, day);
		//console.log('New date:', newDate);

		//Date must be after 01/01/2010
		if (year < 2010)
		{
			setDate(new Date(2010, 0, 1));
			setYear(2010);
			setMonth(0);
			setDay(1);
			setDateString('01/01/2010');
			return;
		}

		//Date must be today or before
		if (newDate > new Date())
		{
			setDate(new Date());
			setYear(new Date().getFullYear());
			setMonth(new Date().getMonth());
			setDay(new Date().getDate());
			setDateString(format(new Date(), dateFormat));
			return;
		}

		//console.log('Year:', year, 'Month:', month, 'Day:', day);
		setDate(newDate);
	}, [year, month, day]);

	useEffect(() =>
	{
		//console.log('Date:', date);
		//Update datestring
		//If date is 2010-01-01, show as 'Any date', otherwise show as date
		if (date.getFullYear() == 2010 && date.getMonth() == 0 && date.getDate() == 1) setDateString('Any date');
		else
		{
			try
			{
				setDateString(format(date, dateFormat));
			}
			catch (e)
			{
				setDateString(new Date().toDateString());
			}
		}
	}, [date]);

	const [open, setOpen] = useState(false);
	const inputRef = useRef<any>(null);

	//Focus the input when the popover opens
	useEffect(() =>
	{
		if (open)
		{
			//console.log('Focusing on input');
			inputRef.current?.focus();
			inputRef.current?.focus();
			setTimeout(() =>
			{
				inputRef.current?.focus();
			}, 50);
		}
	}, [open]);

	//Add paste listener to input
	useEffect(() =>
	{
		const pasteListener = (e: any) =>
		{
			//console.log(e);
			//console.log(window.event);

			if (!e.clipboardData) return;

			//Make sure THIS input is the one that's focused or hovered over
			if (document.activeElement !== inputRef.current && !inputRef.current?.contains(e.target)) return;

			//Get date, set to datestring
			const paste = e.clipboardData.getData('text');
			setDateString(paste);
			e.preventDefault();
		};

		document.addEventListener('paste', pasteListener);

		return () =>
		{
			document.removeEventListener('paste', pasteListener);
		};
	}, [inputRef.current]);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<Tooltip open={formatTooltipOpen} onOpenChange={setFormatTooltipOpen}>
				<TooltipTrigger className='w-full'>
					<PopoverTrigger
						className={
							'flex flex-row w-full items-center justify-center p-2 rounded-md border text-sm ' +
							(matchesFormat ? 'border-neutral-300' : 'border-maven-error-400') +
							' group'
						}
					>
						<CalendarIcon className='mr-2 h-4 w-4' />
						<input
							ref={inputRef}
							className='flex w-full outline-0 ring-0'
							type='string'
							value={dateString}
							onClick={e =>
							{
								//console.log('Clicked on date picker');
								e.stopPropagation();
								if (!open) setOpen(true);
							}}
							onChange={e =>
							{
								setDateString(e.target.value);

								if (inputTimer)
								{
									clearTimeout(inputTimer);
								}

								setInputTimer(
									setTimeout(() =>
									{
										//Try parse date w/ regex - , mm, some separator, dd, some separator, yyyy
										const dateMatch = e.target.value.match(dateRegex);
										if (dateMatch)
										{
											//console.log('Matched:', dateMatch);
											setMatchesFormat(true);
											setMonth(parseInt(dateMatch[1]) - 1);
											setDay(parseInt(dateMatch[2]));
											setYear(parseInt(dateMatch[3]));
										}
										else
										{
											//console.log('No match');
											setMatchesFormat(false);
											setFormatTooltipOpen(true);
										}
										setInputTimer(null);
									}, 500)
								);
							}}
							onPaste={e =>
							{
								//Get date, set to datestring
								const paste = e.clipboardData.getData('text');
								setDateString(paste);
								e.preventDefault();
							}}
						/>
						<CopyText text={dateString} />
					</PopoverTrigger>
				</TooltipTrigger>
				<Portal>
					<TooltipContent className={cn('p-2 hidden md:flex', z_classes.overlay[2])}>
						<p>Date Format - MM/DD/YYYY</p>
					</TooltipContent>
				</Portal>
			</Tooltip>

			<PopoverContent
				className={cn('w-auto p-0', z_classes.overlay[3])}
				onClick={e =>
				{
					e.stopPropagation();
				}}
			>
				<Calendar
					date={date}
					onSelect={(date: Date) =>
					{
						//console.log('Date picker chose:', date);
						if (!date) return;

						//setDay(date.getDate()); // this bug has been fixed
						setDate(date);
					}}
					Caption={
						<div className='flex flex-row w-full items-center justify-evenly'>
							<button
								className='flex h-full items-center justify-center text-icon cursor-pointer'
								onClick={() =>
								{
									setMonth((month - 1 + 12) % 12);
									if (month == 0) setYear(Math.max(2010, year - 1));
								}}
							>
								<ChevronLeft />
							</button>
							<div className='min-w-[15ch] pr-2'>
								<EnumDropdown
									enumValues={months}
									value={months[month]}
									onChange={(newMonth: string) =>
									{
										setMonth(months.indexOf(newMonth));
									}}
									contentClassName='z-[50000]'
								/>
							</div>

							<EnumDropdown
								enumValues={years.map(year => year.toString())}
								value={year.toString()}
								onChange={(newYear: string) =>
								{
									setYear(parseInt(newYear));
								}}
								contentClassName='z-[50000]'
							/>
							<button
								className='flex h-full items-center justify-center text-icon cursor-pointer'
								onClick={() =>
								{
									//If next month is in the future, do nothing
									if (month == new Date().getMonth() && year == new Date().getFullYear())
									{
										return;
									}

									setMonth((month + 1) % 12);
									if (month == 11) setYear(Math.min(year + 1, new Date().getFullYear()));
								}}
							>
								<ChevronRight />
							</button>
						</div>
					}
				/>
			</PopoverContent>
		</Popover>
	);
}
