export default function reverseFormat(name: string) 
{
	if(!name) 
        return 'empty';

    if(typeof name !== 'string')
        return name;

    return name
        .replace(/\\s+/gi, '_')
        .replace('-', '_')
		.replace(/ /gi, '_')
		.replace(/[^a-z0-9_]/gi, '')
		.toLowerCase();
}