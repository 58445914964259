'use client';

import Keyboard from '@material-symbols/svg-400/outlined/keyboard.svg';
import { memo, useEffect, useState } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import store from '@/redux/store';
import { setOverlay } from '@/redux/layoutSlice';
import newChat from '@/utils/mutateSession/newChat';
import { Portal } from '@radix-ui/react-tooltip';
import { cn } from '@/utils/lib/utils';
import { z_classes } from '@/utils/z';

type Modifier = '^' | '+' | '!';

type Bind = {
	key: string;
	action: (event?: any) => void;
	modifiers: Modifier[];
	group?: string;
	description: string;
	allowAnytime?: boolean;
	allowDefault?: boolean;
};

const binds: Bind[] = [
	{
		key: '[',
		action: () =>
		{
			const element = document.getElementById('left-sidebar-toggle');
			if (element)
			{
				element.click();
			}
		},
		modifiers: [],
		group: 'Layout',
		description: 'Toggle the navigation panel'
	},
	{
		key: ']',
		action: () =>
		{
			const element = document.getElementById('right-sidebar-toggle');
			if (element)
			{
				element.click();
			}
		},
		modifiers: [],
		group: 'Layout',
		description: 'Toggle the Assistant panel'
	},
	{
		key: '}',
		action: () =>
		{
			const element = document.getElementById('right-sidebar-fullscreen-toggle');
			if (element)
			{
				element.click();
			}
		},
		modifiers: ['+'],
		group: 'Layout',
		description: 'Expand Assistant (right) panel to full width'
	},
	{
		key: '?',
		action: () =>
		{
			//setShowKeybindHelp(!showKeybindHelp);
			//Find the keybind help button, and click it
			const element = document.getElementById('help-trigger');
			if (element)
			{
				element.click();
			}
		},
		modifiers: ['+'],
		group: 'Layout',
		description: 'Show help center overlay'
	},
	{
		key: 'Enter',
		action: e =>
		{
			//If the company lookup is focused, then click the go button
			console.log('Enter key pressed', e.target);

			if (e.target.id === 'company-lookup-dropdown')
			{
				const element = document.getElementById('company-lookup-go');
				if (element)
				{
					element.click();
				}
				return;
			}
			else if ((e.target.id === 'docSearchInput' || e.target.id === 'docSearchInputFacade') && e.key === 'Enter')
			{
				//Update search
				const docSearchButton = document.getElementById('docSearchButton');
				if (docSearchButton)
				{
					docSearchButton.click();
					return;
				}
			}
			else if (e.target.getAttribute('data-blur-on-enter') === true || e.target.getAttribute('data-blur-on-enter') === 'true')
			{
				console.log('Blurring element', e.target);
				e.target.blur();
			}
			else if (e.target.id.includes('action-input'))
			{
				//Find all elements with the id 'send-message', and click them:
				console.log('Sending message');
				const elements = document.querySelectorAll('[id="send-message"]');
				elements.forEach(element =>
				{
					console.log('Clicking element:', element);
					if (element) (element as HTMLElement).click();
				});

				e.preventDefault();
			}
		},
		modifiers: [],
		group: 'Chat',
		description: 'Send Message',
		allowAnytime: true,
		allowDefault: true
	},
	{
		key: '\\',
		action: () =>
		{
			//If the main panel is collapsed, then focus on the right sidebar
			const state = store.getState();
			if (state.layout.mainPanelCollapsed)
			{
				const targetElement = document.getElementById('action-input')!;
				const range = document.createRange();
				const selection = window.getSelection();
				range.selectNodeContents(targetElement);
				range.collapse(false); // false parameter sets the cursor at the end
				selection?.removeAllRanges();
				selection?.addRange(range);
			}

			//Otherwise, there might be multiple chat inputs, so we need to find the one that is visible
			const inputElements = document.querySelectorAll('[id^="action-input"]');

			if (inputElements.length === 0)
			{
				console.error('No input elements to focus on');
				return;
			}

			//Find the widest input to show:
			let targetElement: HTMLElement | null = inputElements[0] as HTMLElement;
			inputElements.forEach(element =>
			{
				if (element.clientWidth > targetElement!.clientWidth)
				{
					targetElement = element as HTMLElement;
				}
			});

			if (targetElement)
			{
				console.log('Focusing on element:', targetElement);
				targetElement.focus();
				if (targetElement instanceof HTMLInputElement || targetElement instanceof HTMLTextAreaElement)
				{
					targetElement.selectionStart = targetElement.value.length;
					targetElement.selectionEnd = targetElement.value.length;
				}
				else
				{
					const range = document.createRange();
					const selection = window.getSelection();
					range.selectNodeContents(targetElement);
					range.collapse(false); // false parameter sets the cursor at the end
					selection?.removeAllRanges();
					selection?.addRange(range);
				}
			}
		},
		modifiers: [],
		group: 'Chat',
		description: 'Focus your cursor on the Assistant message input field'
	},
	{
		key: 'C',
		action: () =>
		{
			newChat();
		},
		modifiers: ['+'],
		group: 'Chat',
		description: 'Create a new Assistant chat'
	},
	{
		key: 'H',
		action: () =>
		{
			//Find element with the id 'new-session-action', and click it
			const newSessionAction = document.getElementById('chat-history-trigger') as HTMLElement;
			newSessionAction?.click();
		},
		modifiers: ['+'],
		group: 'Chat',
		description: 'View Assistant chat history within this session'
	},
	{
		key: 'N',
		action: () =>
		{
			//Find element with the id 'new-session-action', and click it
			const newSessionAction = document.getElementById('new-session-action') as HTMLElement;
			newSessionAction?.click();
		},
		modifiers: ['+'],
		group: 'Session',
		description: 'Create a new session'
	},
	{
		key: 'T',
		action: () =>
		{
			//Find element with the id 'new-tab-action', and click it
			const newSessionAction = document.getElementById('new-tab-action') as HTMLElement;
			newSessionAction?.click();
		},
		modifiers: ['+'],
		group: 'Session',
		description: 'Create a new content tab'
	},
	{
		key: 'Escape',
		action: () =>
		{
			store.dispatch(setOverlay(null));
		},
		modifiers: [],
		group: 'Layout',
		description: 'Close any open overlays'
	}
];

function getModifiersString(modifiers: Modifier[])
{
	let modifiersString = '';

	modifiers.forEach(modifier =>
	{
		switch (modifier)
		{
			case '^':
				modifiersString += 'Ctrl+';
				break;
			case '+':
				modifiersString += 'Shift+';
				break;
			case '!':
				modifiersString += 'Alt+';
				break;
		}
	});

	return modifiersString;
}

const KeyDisplay = ({ keyString, modifiers }: { keyString: string; modifiers: Modifier[] }) =>
{
	return (
		<div className='flex flex-row items-center rounded-md bg-neutral-100 p-2 '>
			<Keyboard className='text-neutral-400 text-icon min-w-6' />
			<p className='pl-2'>
				{getModifiersString(modifiers)}
				{keyString}
			</p>
		</div>
	);
};

const KeybindManager = () =>
{
	const [showKeybindHelp, setShowKeybindHelp] = useState(false);

	useEffect(() =>
	{
		const handleKeyDown = (event: any) =>
		{
			//If inside a textarea or input, don't consume these commands
			var el = document.activeElement;

			binds.forEach(bind =>
			{
				//Skip keydown events if not in an input or textarea, unless allowAnytime is set to true
				if (!bind.allowAnytime && el && (el.id.includes('action') || el.tagName.toLowerCase() === 'input' || el.tagName.toLowerCase() === 'textarea'))
				{
					//console.log('Ignoring keydown event');
					return;
				}
				if (bind.allowAnytime && el)
				{
					//console.log('Allowing keydown event:', el.id, el.tagName.toLowerCase());
				}

				if (event.key === bind.key)
				{
					//console.log('Executing bind:', bind);

					//Check for modifiers
					if (bind.modifiers.length > 0)
					{
						let hasModifiers = true;

						bind.modifiers.forEach(modifier =>
						{
							switch (modifier)
							{
								case '^':
									if (!event.ctrlKey && !event.metaKey)
									{
										hasModifiers = false;
									}
									break;
								case '+':
									if (!event.shiftKey)
									{
										hasModifiers = false;
									}
									break;
								case '!':
									if (!event.altKey)
									{
										hasModifiers = false;
									}
									break;
							}
						});

						if (!hasModifiers)
						{
							return;
						}
					}

					bind.action(event);

					if (!bind.allowDefault)
					{
						event.preventDefault();
					}
				}
			});
		};

		window.addEventListener('keydown', handleKeyDown);

		return () =>
		{
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	//Sort binds into groups
	const groups: { [key: string]: Bind[] } = {};

	binds.forEach(bind =>
	{
		if (bind.group === undefined)
		{
			bind.group = 'General';
		}

		if (!groups[bind.group])
		{
			groups[bind.group] = [];
		}

		groups[bind.group].push(bind);
	});

	//console.log(groups);

	useEffect(() =>
	{
		if (showKeybindHelp)
		{
			store.dispatch(
				setOverlay({
					content: (
						<div
							id='keyboard-help-overlay'
							className='flex flex-col w-[90dvw] md:w-3/4 2xl:w-1/2 h-3/4 overflow-y-auto bg-white rounded-md border border-neutral-200 p-6 cursor-default'
							onClick={e =>
							{
								e.stopPropagation();
							}}
						>
							<div className='flex flex-col gap-y-4'>
								<h2 className='text-xl font-bold'>Keybinds</h2>
								{Object.keys(groups).map((groupKey, index) =>
								{
									const group = groups[groupKey];
									return (
										<div className='flex flex-col' key={index}>
											<p className=''>{groupKey}</p>
											<div className='grid grid-cols-2 gap-2'>
												{group.map((bind, index) =>
												{
													return (
														<div key={index} className='text-base flex flex-row rounded-md p-1 border-neutral-100 border '>
															<div className=' border-r-0 px-2 h-full w-full flex flex-row items-center rounded-l-md'>
																{bind.description}
															</div>
															<KeyDisplay keyString={bind.key} modifiers={bind.modifiers} />
														</div>
													);
												})}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					),
					closeCallback: () =>
					{
						console.log('Closing keybind help');
						setShowKeybindHelp(false);
					}
				})
			);
		}
		else
		{
			store.dispatch(setOverlay(null));
		}
	}, [showKeybindHelp]);

	return (
		<div
			id='keyboard-help-trigger'
			className='hidden p-1 rounded-full overflow-visible cursor-pointer '
			onClick={e =>
			{
				setShowKeybindHelp(!showKeybindHelp);
			}}
		>
			<Keyboard className='text-neutral-400 hover:text-neutral-900 text-icon ' />
		</div>
	);
};

const KeybindTooltip = memo(({ bind, children, side = 'right' }: { bind: string | Bind; children: any; side?: 'right' | 'left' | 'top' | 'bottom' }) =>
{
	//Find the corresponding bind, and display it in a tooltip
	let bindData;
	if (typeof bind === 'object')
	{
		bindData = bind;
	}
	else
	{
		bindData = binds.find(b => b.modifiers.join() + b.key === bind);
	}

	if (!bindData)
	{
		return children;
	}

	return (
		<Tooltip delayDuration={200} disableHoverableContent>
			<TooltipTrigger asChild>{children}</TooltipTrigger>
			<Portal>
				<TooltipContent
					align='start'
					side={side}
					className={cn('flex flex-row items-center justify-center gap-x-2 p-1 font-normal', z_classes.overlay[1])}
				>
					<p className='px-2 '>{bindData.description}</p>
					<KeyDisplay keyString={bindData.key} modifiers={bindData.modifiers} />
				</TooltipContent>
			</Portal>
		</Tooltip>
	);
});
KeybindTooltip.displayName = 'KeybindTooltip';

const KeybindsPanel = () =>
{
	const groups: { [key: string]: Bind[] } = {};

	binds.forEach(bind =>
	{
		if (bind.group === undefined)
		{
			bind.group = 'General';
		}

		if (!groups[bind.group])
		{
			groups[bind.group] = [];
		}

		groups[bind.group].push(bind);
	});

	return (
		<div
			id='keyboard-help-overlay'
			className='flex flex-col w-full h-full overflow-y-auto bg-white rounded-md cursor-default'
			onClick={e =>
			{
				e.stopPropagation();
			}}
		>
			<div className='flex flex-col gap-y-4 p-2'>
				{Object.keys(groups).map((groupKey, index) =>
				{
					const group = groups[groupKey];
					return (
						<div className='flex flex-col' key={index}>
							<p className='text-xl font-bold pb-4'>{groupKey}</p>
							<div className='flex flex-col @lg:grid @lg-grid-cols-2 @2xl:grid-cols-3 @[92rem]:grid-cols-4 gap-4'>
								{group.map((bind, index) =>
								{
									return (
										<div key={index} className='text-base flex flex-row rounded-md p-1 border-neutral-100 border '>
											<div className=' border-r-0 px-1 h-full w-full flex flex-row items-center rounded-l-md'>{bind.description}</div>
											<KeyDisplay keyString={bind.key} modifiers={bind.modifiers} />
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default KeybindManager;
export { KeyDisplay, KeybindTooltip, KeybindsPanel };
